/* eslint-disable no-undef */
import React,{ useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ButtonRound from '../components/ButtonRound';
import ButtonTransparent from '../components/ButtonTransparent';

import IconBack from '../assets/img/ic-back-white.svg';
import IconImg from '../assets/img/ic-image.svg';
import IconLoading from '../assets/img/ic-loading.svg'

let html5QrCode;
let cameraActive = false;
let uploadAction = false;

function Scan() {
    const history = useNavigate();

    const [scanData, setScanData] = useState('')
    const [qrcodeData, setQrcodeData] = useState('')
    const [scanDataDetail, setScanDataDetail] = useState([])
    const [scanDataList, setScanDataList] = useState([])

    const [errMessage, setErrMessage] = useState('ไม่สามารถเข้าระบบได้')
    const [errCameraMessage] = useState('ไม่สามารถทำรายการได้ เนื่องจากระบบไม่พบกล้องในอุปกรณ์')
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
    const [modalErrorCameraIsOpen, setModalErrorCameraIsOpen] = useState(false)
    const [modalErrorScanIsOpen, setModalErrorScanIsOpen] = useState(false)

    const [camObj, setCamObj] = useState([]);
    const [modalCameraIsOpen, setModalCameraIsOpen] = useState(false);
    const [modalInsertCodeIsOpen,setModalInsertCodeIsOpen] = useState(false)
    const [btnActive, setBtnActive] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    let apiLocation = localStorage.getItem('authLocation');
    let apiXKey = localStorage.getItem('authXkey')

    useEffect(() => {
        setModalInsertCodeIsOpen(false)
        setModalCameraIsOpen(false)
        setModalErrorIsOpen(false)
        setModalErrorScanIsOpen(false)

        html5QrCode = new Html5Qrcode("camera-reader");
    
        setIsLoading(true)
        let unamounted = false

        const fetchStatus =  async() => {
            const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/peddleruser/status`, {
                method: 'GET',
                // body: JSON.stringify(dataLogin),
                headers: {
                    "x-application-secret-key": apiXKey,
                    "Authorization": "Bearer "+localStorage.getItem('authToken'),
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            res
            .json()
            .then(res => {
                if(!unamounted) {
                    console.log('data status:',res)
                    setIsLoading(false)
                    // history.push('/')
                    unamounted = true

                    // localStorage.removeItem('authData')
                    Html5Qrcode.getCameras().then(devices => {
            
                        if (devices && devices.length) {
                            // console.log(devices[0].id);
                            setCamObj(devices);
                            setModalCameraIsOpen(true);
                        } 
                        
                    }).catch(err => {
                        console.log(err)
                        // setErrMessage("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                        setModalErrorCameraIsOpen(true);
                    });
                }
            })
            .catch(err => {
                console.log(err);
                history('/')
                setIsLoading(false)
            });
        }

        // if(!!localStorage.getItem('authLocation')) {
        //     fetchStatus()
        // } else {
        //     history('/')
        // }
        fetchStatus()

        return ()=> { 
            unamounted = true 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const fetchScan =  async(qr, status) => {
        setIsLoading(true)
        const res = await fetch(`https://${apiLocation}/ecp/api/peddleruser/scan_qrcode`, {
            method: 'POST',
            // body: JSON.stringify(dataLogin),
            body: JSON.stringify({
                qrcode: qr,
            }),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });
  
        res
        .json()
        .then(res => {
            setIsLoaded(true)
            setIsLoading(false)
            console.log('data login:',res)
            if (res.success === 0) {
                setErrMessage(res.message === 'Not found' ? 'รหัส QR code ไม่ถูกต้อง' : res.message)
                if(status === 'scan') {
                    setModalErrorScanIsOpen(true)
                } else {
                    setModalErrorIsOpen(true);
                }
  
                
            } else {
                const data = res;
                console.log(data)
                setScanDataDetail(data)
                setScanDataList(data.ecp_list)
                setQrcodeData(qr)
                setConfirm(true)
            }
            console.log('cameraActive:',cameraActive)
            if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
  
        })
        .catch(err => {
            setIsLoaded(true)
            setIsLoading(false)
            console.log(err);
            
            setErrMessage('รหัส QR Code ไม่ถูกต้อง')
            if(status === 'scan') {
                setModalErrorScanIsOpen(true)
            } else {
                setModalErrorIsOpen(true);
            }
        });
  
    }

    const fetchAccept = async () => {
        
        const res = await fetch(`https://${apiLocation}/ecp/api/peddleruser/accept_coupons`, {
            method: 'POST',
            body: JSON.stringify({
                "qrcode": qrcodeData
            }),
            headers: {
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            if (res.success === 0) {
                setModalErrorIsOpen(true);
                // setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้');
                // setIsLoaded(true);
                setConfirm(false)
                if(Object.keys(res.message).length !== 0) {
                    console.error(res)
                    if(typeof res.message === 'object') {
                        setErrMessage('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                    } else {
                        setErrMessage(res.message)
                    }
                } else {
                    setErrMessage('ไม่สามารถทำรายการได้')
                }

            } else {
                const data = res;
                console.log(data)
                closeModal()
            }

        })
        .catch(err => {
            console.log(err);
            setIsLoading(false)
            setModalErrorIsOpen(true);
        });
    }

    function chooseCamera(cameraId) {
        // console.log(camId)
        setIsLoaded(true)
        setModalCameraIsOpen(false)
        cameraActive = true;

        html5QrCode.start(
        cameraId, 
        {
            fps: 10,    // Optional frame per seconds for qr code scanning
            qrbox: 200,  // Optional if you want bounded box UI
            aspectRatio: 1.7777778
        },
        qrCodeMessage => {
            console.log('ScanCode',qrCodeMessage);
            fetchScan(qrCodeMessage, 'scan');
            html5QrCode.stop(); 
            cameraActive = false

        },
        errorMessage => {
            // parse error, ignore it.
        })
        .catch(err => {
        // Start failed, handle it.
        });
    }

    const checkCameraActive = () => {
        // checkCameraActive
        uploadAction = true
        if(cameraActive) { cameraActive=false; html5QrCode.stop(); }

        // checkQrcodeMessage
        const fileinput = document.getElementById('qr-input-file'); 
        fileinput.addEventListener('change', e => {
            if (e.target.files.length === 0) {
                
                return false;
            }

            const imageFile = e.target.files[0];
            // Upload QR Code
            html5QrCode.scanFile(imageFile, false)
            .then(qrCodeMessage => {
                // success, use decodedText

                setIsLoaded(false)
                console.log(qrCodeMessage);
                fetchScan(qrCodeMessage, 'scan');
                // history.push('/')
            })
            .catch(err => {
                // failure, handle it.
                console.log(`Error scanning file. Reason: ${err}`)
                setErrMessage('QR Codeไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                setModalErrorScanIsOpen(true)
            });
        });
    }

    const gotoHome = () => {
        history('/home')
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
    }
    
    // Event open modal insert code
    const gotoInsertCode = () => {
        setModalInsertCodeIsOpen(true)
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
    }

    // Event input code -----------------------------------------------//
    const handleInputCode = (event) => {
        // console.log(event.target.value)
        !!event.target.value?setBtnActive(true): setBtnActive(false)
        
        setScanData(event.target.value)
        // localStorage.setItem('scanData',event.target.value)
    }

    // Event send insert code
    const sendCode = () => {

        // setScanData(scanData)
        // history.push('/')
        setModalInsertCodeIsOpen(false)
        fetchScan(scanData,'insert')
    } 

    function handleReload() {
        // setModalErrorIsOpen(false);
        setIsLoaded(true)
        setModalCameraIsOpen(false);
    }

    const closeInsertCodeModal = () => {
        setModalInsertCodeIsOpen(false)
        window.location.reload()
    }

    // Event close modal
    const closeModal = () => {
        setModalErrorCameraIsOpen(false)
        setModalCameraIsOpen(false);
        setModalErrorIsOpen(false);
        setModalInsertCodeIsOpen(false);
        history('/home')
        // window.parent.location = localStorage.getItem('windowParentLocation')+'/home';
        // window.location.reload()
        
        // console.log(document.getElementById('qr-input-file').value)
    }

    // Event close modal
    const closeModalCamera = () => {
        setModalErrorCameraIsOpen(false)
        setModalCameraIsOpen(false);
        setModalErrorIsOpen(false);
        setModalInsertCodeIsOpen(false);
        // history('/home')
        // window.parent.location = localStorage.getItem('windowParentLocation')+'/home';
        // window.location.reload()
        
        // console.log(document.getElementById('qr-input-file').value)
    }

    // Event goto home
    const closeModalConfirm = () => {
        setConfirm(false)
        history('/home')
        // window.location.reload()
    }

    // Event close modal upload
    const closeModalScan = () => {
        setModalErrorScanIsOpen(false);

        if(uploadAction) {
            document.getElementById('qr-input-file').value = null;
            // document.getElementById('qr-canvas-visible').remove();
            // document.getElementById('qr-canvas').remove();
            uploadAction = false
        }

        // getScanQrCode();
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
        
        window.location.reload()
    }

  
    Modal.setAppElement('#root');
    return (
        <div className="scan-page txt-center scanqrcode-box">
            {
                isLoading ? <div className="modal-overlay"><img src={IconLoading} alt="...Loading..." /></div> : null
            }
             <div className="_header">
                <div className="btn-back dbrScanner-btn-close" onClick={gotoHome}>
                    <img src={IconBack} alt="icon" />
                </div>
                <div>
                    <h3>ยืนยันรหัส QR</h3>
                    <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
                </div>
            </div>
            <div className="password-scan">

                {/* <img className="qrscanner" src={QrScanner} alt="qrscanner"/> */}
                { isLoaded ? null : <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>}

                {/* { isLoaded ? <div id="camera-reader" className="camera-reader"></div> : null} */}

                <div className="_browsefile">
                    {/* <input type='file' id='file'ref={inputFile} onChange={onIptChange} type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/> */}
                    {/* <button onClick={openImageDialog}>
                        <img src={IconImg} alt="icon" />
                    </button> */}
                    <label htmlFor="qr-input-file" className="custom-file-upload" onClick={checkCameraActive}>
                        <img src={IconImg} alt="icon" />
                    </label>
                    <input type="file" id="qr-input-file" accept="image/*" style={{display: 'none'}}></input>
                </div>
                
                <div id="camera-reader" className="camera-reader"></div>

                <div className="_footer">
                    <ButtonTransparent text={'กรอกรหัสด้วยมือ'} onClick={gotoInsertCode} />
                </div>
            </div>

            <Modal 
                isOpen={modalCameraIsOpen} 
                onRequestClose={handleReload}
                shouldCloseOnOverlayClick={true}
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>กรุณาเลือกกล้องเพื่อสแกน QR Code</p>
                    <br/>
                    <ul style={{listStyle: 'none'}}>
                        { camObj.map((option, index) => (
                            <li key={option.id} style={{marginBottom: '10px'}}>
                                <ButtonRound color="blue" text={(option.label.includes('front') || option.label.includes('Front') || option.label.includes('FRONT') ? 'เปิดใช้กล้องหน้า' : (option.label.includes('back') || option.label.includes('Back') || option.label.includes('BACK') ? 'เปิดใช้กล้องหลัง' : option.label))} onClick={()=>{chooseCamera(option.id)}} />
                            </li>
                        ))}
                    </ul>
                    {/* <Link to={`/main/?query=${query}`}><BtnEllipse name="ยกเลิก"  color="grey" /></Link> */}
                </div>
            </Modal>
            <Modal 
                isOpen={modalInsertCodeIsOpen} 
                className="_modal box-style modal-insertcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_insertcode-modal center">
                    <p>กรอกรหัส QR ในช่องด้านล่าง</p>
                    <input type="text" name="insertcode" value={scanData} onChange={handleInputCode} />
                    <br/>
                    <div className="btn-row">
                        <ButtonRound text="ยกเลิก" color="grey" onClick={closeInsertCodeModal}/>
                        &nbsp;&nbsp;&nbsp;
                        {
                            btnActive ? 
                            <ButtonRound text="ถัดไป" color="blue" onClick={sendCode}/>
                            :
                            <ButtonRound text="ถัดไป" color="blue" style={{opacity: '0.5', pointerEvents: 'none'}} />
                        }
                        
                    </div>
                </div>
            </Modal>
            <Modal 
                isOpen={confirm}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณต้องการให้ส่วนลด { !!(scanDataDetail.total_face_value) ? (scanDataDetail.total_face_value).toLocaleString('en-US') : 0 } บาท { !!scanDataDetail.total_minimum_purchase ? `(ยอดซื้อขั้นต่ำ ${scanDataDetail.total_minimum_purchase } แถว)` : null } <br/>ให้กับคุณ { scanDataDetail.gt_name } โดยแลกกับคูปอง</h3>
                    {/* {qrCodeData} */}
                    <ul className="modal-coupon-list">
                        {
                            scanDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        {/* <h3>{i+1}. คูปอง {!!(item.face_value) ? (item.face_value).toLocaleString('en-US') : 0} + <span className="font-red">{!!(item.topup) ? (item.topup).toLocaleString('en-US') : 0} *</span> บาท</h3> */}
                                        <h3>{i+1}. คูปอง {!!(item.face_value) ? (item.face_value).toLocaleString('en-US') : 0} บาท</h3>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                    {/* <p className="font-red">* ท๊อปอัพเพิ่มเติมจาก PMI</p> */}
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="grey" text="ยกเลิก" onClick={closeModalConfirm} />
                        &nbsp;&nbsp;&nbsp;
                        <ButtonRound color="blue" text="ยืนยัน" onClick={fetchAccept} />
                    </div>

                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errMessage}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errMessage}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorCameraIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errMessage}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errCameraMessage}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModalCamera}/>
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorScanIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errMessage}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errMessage}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModalScan}/>
                </div>
            </Modal>
        </div>
    )
}

export default Scan