import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ButtonRound from '../components/ButtonRound';

function Login() {
  const history = useNavigate();

  const [dataLogin, setDataLogin] = useState({
      username: '',
      password: '',
  })

  const [userPMI, setUserPMI] = useState(false)

  const [errUserPMIShow, setErrUserPMIShow] = useState(false)
  const [errUsername, setErrUsername] = useState(false)

  const checkUserPMI = () => {

    if(userPMI && !!dataLogin.username) {
      history('/setpassword', { state: { username: dataLogin.username } })

    } else {

      if(userPMI) {
        setErrUserPMIShow(false)
      } else {
        setErrUserPMIShow(true)
      }

      if(!!dataLogin.username) {
        setErrUsername(false)
      } else {
        setErrUsername(true)
      }

    }
  }

  return (
    <div className="login-page txt-center container">
        <h3>เข้าสู่ระบบ</h3>
        <p>Hello , welcome back to our account!</p>
        <div>
            <input type="text" placeholder="Username" name="username" className="input-text" value={dataLogin.username} onChange={(e)=>{ setDataLogin({...dataLogin, [e.target.name]: e.target.value})}}/>
        </div>
        <div className="txt-left">
            <input type="checkbox" id="approve" onChange={(e)=>{ setUserPMI(e.target.checked); setErrUserPMIShow(false) }} />&nbsp;
            <label htmlFor="approve">ข้าพเจ้ายินยอมว่าเป็นผู้ค้าส่งของ PMI</label>
        </div>
        <span className="txt-error">
          { errUserPMIShow && errUsername ? 'กรุณากรอก Username และ กดยินยอมเงื่อนไข' : errUserPMIShow ? 'กรุณากดยินยอมเงื่อนไข' : errUsername ? 'กรุณากรอก Username' : '' }
        </span>
        <div className="btn-row txt-center">
          <ButtonRound color="blue" text="เข้าสู่ระบบ" onClick={()=>{ checkUserPMI() }} />
        </div>
    </div>
  )
}

export default Login