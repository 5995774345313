/* eslint-disable no-undef */
import React,{ useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import ButtonRound from '../components/ButtonRound';
import ButtonTransparent from '../components/ButtonTransparent';
import AuthContext from '../components/AuthContext';

import IconBack from '../assets/img/ic-back-white.svg';
import IconImg from '../assets/img/ic-image.svg';
import IconLoading from '../assets/img/ic-loading.svg'

let html5QrCode;
let cameraActive = false;
let uploadAction = false;

function SetPassword() {
    const history = useNavigate();
    const location = useLocation();
    const { setAuthData } = useContext(AuthContext)

    const [dataLogin, setDataLogin] = useState({
        username: '',
        password: '',
    })

    const [errMessage, setErrMessage] = useState('ไม่สามารถเข้าระบบได้')
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
    const [modalErrorScanIsOpen, setModalErrorScanIsOpen] = useState(false)

    const [insertPassword, setInsertPassword] = useState(true)
    const [camObj, setCamObj] = useState([]);
    const [modalCameraIsOpen, setModalCameraIsOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        html5QrCode = new Html5Qrcode("camera-reader");
      !!location.state? setDataLogin({...dataLogin, username: location.state.username}) : history('/')
    
      return () => {
        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const fetchLogin =  async(pwd, status) => {
        setIsLoading(true)
        let apiLocation = '';
        let apiXKey = ''
        
        console.log('window.location.origin',window.location.origin);
  
        switch(window.location.origin) {
            // Local
            case "http://localhost:3000": 
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;
  
            case "http://localhost:3001": 
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;
  
            // Staging
            case "https://peddler-sandbox.vivi-th.com":
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;
  
            // Production
            default:
            apiLocation = 'api.vipwallet.vivi-th.com';
            apiXKey = "756dc69c-4c3e-4724-aa3d-0cf27010c102";
            break;
        }
  
        const res = await fetch(`https://${apiLocation}/ecp/api/peddleruser/login`, {
            method: 'POST',
            // body: JSON.stringify(dataLogin),
            body: JSON.stringify({
                username: location.state.username,
                password: pwd,
            }),
            headers: {
                "x-application-secret-key": apiXKey,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
  
        res
        .json()
        .then(res => {
            setIsLoaded(true)
            setIsLoading(false)
            console.log('data login:',res)
            if (res.success === 0 || res.code === 0) {
                setErrMessage(res.message)
                if(status === 'scan') {
                    setModalErrorScanIsOpen(true)
                } else {
                    setModalErrorIsOpen(true);
                }
  
                
            } else {
                const data = res;
                // console.log(data)
                setAuthData(data)
                history('/home')
                localStorage.setItem('authToken',data.token)
                localStorage.setItem('authXkey',apiXKey)
                localStorage.setItem('authLocation',apiLocation)
                localStorage.setItem('windowParentLocation',data.parent_location)
                localStorage.setItem('lastLogin',data.last_login)
                // localStorage.setItem('authData',JSON.stringify(data))
                // if(cameraActive) { cameraActive=false; html5QrCode.stop(); }
            }
  
        })
        .catch(err => {
            setIsLoaded(true)
            setIsLoading(false)
            console.log(err);
            
            setErrMessage('ไม่สามารถเข้าระบบได้')
            if(status === 'scan') {
                setModalErrorScanIsOpen(true)
            } else {
                setModalErrorIsOpen(true);
            }
        });
  
    }

    const getScanQrCode = () => {
        setInsertPassword(false)
        setIsLoaded(false)

        Html5Qrcode.getCameras().then(devices => {
            
            if (devices && devices.length) {
                // console.log(devices[0].id);
                setCamObj(devices);
                setModalCameraIsOpen(true);
            } 
            
        }).catch(err => {
            console.log(err)
            setErrMessage("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
            setModalErrorIsOpen(true);
        });
        
    }

    function chooseCamera(cameraId) {
        // console.log(camId)
        setIsLoaded(true)
        setModalCameraIsOpen(false)
        cameraActive = true;

        html5QrCode.start(
        cameraId, 
        {
            fps: 10,    // Optional frame per seconds for qr code scanning
            qrbox: 200,  // Optional if you want bounded box UI
            aspectRatio: 1.7777778
        },
        qrCodeMessage => {
            console.log('ScanCode',qrCodeMessage);
            fetchLogin(qrCodeMessage, 'scan');
            // handleScanQrcode(qrCodeMessage)
            html5QrCode.stop(); 
            cameraActive = false

        },
        errorMessage => {
            // parse error, ignore it.
        })
        .catch(err => {
        // Start failed, handle it.
        });
    }

    const checkCameraActive = () => {
        // checkCameraActive
        uploadAction = true
        if(cameraActive) { cameraActive=false; html5QrCode.stop(); }

        // checkQrcodeMessage
        const fileinput = document.getElementById('qr-input-file'); 
        fileinput.addEventListener('change', e => {
            if (e.target.files.length === 0) {
                
                return false;
            }

            const imageFile = e.target.files[0];
            // Upload QR Code
            html5QrCode.scanFile(imageFile, false)
            .then(qrCodeMessage => {
                // success, use decodedText

                setIsLoaded(false)
                console.log(qrCodeMessage);
                fetchLogin(qrCodeMessage, 'scan');
                // handleScanQrcode(qrCodeMessage)
                // history.push('/')
            })
            .catch(err => {
                // failure, handle it.
                console.log(`Error scanning file. Reason: ${err}`)
                setErrMessage('QR Codeไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                setModalErrorScanIsOpen(true)
            });
        });
    }

    const gotoHome = () => {
        history('/')
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
    }
    
    const gotoInsertCode = () => {
        setInsertPassword(true)
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
    }

    function handleReload() {
        // setModalErrorIsOpen(false);
        setIsLoaded(true)
        setModalCameraIsOpen(false);
    }

    // Event close modal
    const closeModal = () => {
        setModalCameraIsOpen(false);
        setModalErrorIsOpen(false);
        // setModalInsertCodeIsOpen(false);
        
        // console.log(document.getElementById('qr-input-file').value)
    }

    // Event close modal upload
    const closeModalScan = () => {
        setModalErrorScanIsOpen(false);
        // window.location.reload()

        if(uploadAction) {
            document.getElementById('qr-input-file').value = null;
            // document.getElementById('qr-canvas-visible').remove();
            // document.getElementById('qr-canvas').remove();
            uploadAction = false
        }

        // getScanQrCode();
        setInsertPassword(true)
        if(cameraActive) {cameraActive=false; html5QrCode.stop(); }
        
    }

  
    Modal.setAppElement('#root');
    return (
        <div className="setpassword-page txt-center scanqrcode-box">
            {
                isLoading ? <div className="modal-overlay"><img src={IconLoading} alt="...Loading..." /></div> : null
            }
             <div className="_header">
                <div className="btn-back dbrScanner-btn-close" onClick={gotoHome}>
                    <img src={IconBack} alt="icon" />
                </div>
                {
                    insertPassword ? 
                    <div>
                        <h3 style={{lineHeight: '1.5'}}>ยืนยันรหัส QR</h3>
                    </div>
                    :
                    <div>
                        <h3>ยืนยันรหัส QR</h3>
                        <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
                    </div>
                }
            </div>
            {
                insertPassword ?
                <div className="password-insert container">
                    {/* Fake camera reader */}
                    <div id="camera-reader" className="camera-reader"></div>


                    <h2>กรอกรหัส QR ในช่องด้านล่าง</h2>
                    <div>
                        <input type="password" placeholder="Password" name="password" className="input-text" value={dataLogin.password} onChange={(e)=>{ setDataLogin({...dataLogin, [e.target.name]: e.target.value})}}/>
                    </div>
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="ถัดไป" onClick={()=>{fetchLogin(dataLogin.password, 'insert')}} />
                    </div>
                    <div className="btn-row txt-center">
                        <ButtonRound color="grey" text="ยกเลิก" onClick={()=>{ history('/')}} />
                    </div>
                    <div className="divider">
                        <p>or</p>
                    </div>
                    <div className="btn-row txt-center">
                        <ButtonRound color="whitegrey" text="สแกน QR" onClick={()=>{ getScanQrCode() }} />
                    </div>
                    {/* {
                        errShow ? <p className="font-red">{errMessage}</p> : null
                    } */}
                </div>
                :
                <div className="password-scan">

                    {/* <img className="qrscanner" src={QrScanner} alt="qrscanner"/> */}
                    { isLoaded ? null : <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>}

                    {/* { isLoaded ? <div id="camera-reader" className="camera-reader"></div> : null} */}

                    <div className="_browsefile">
                        {/* <input type='file' id='file'ref={inputFile} onChange={onIptChange} type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/> */}
                        {/* <button onClick={openImageDialog}>
                            <img src={IconImg} alt="icon" />
                        </button> */}
                        <label htmlFor="qr-input-file" className="custom-file-upload" onClick={checkCameraActive}>
                            <img src={IconImg} alt="icon" />
                        </label>
                        <input type="file" id="qr-input-file" accept="image/*" style={{display: 'none'}}></input>
                    </div>
                    
                    <div id="camera-reader" className="camera-reader"></div>

                    <div className="_footer">
                        <ButtonTransparent text={'กรอกรหัสด้วยมือ'} onClick={gotoInsertCode} />
                    </div>
                </div>
            }

            <Modal 
                isOpen={modalCameraIsOpen} 
                onRequestClose={handleReload}
                shouldCloseOnOverlayClick={true}
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>กรุณาเลือกกล้องเพื่อสแกน QR Code</p>
                    <br/>
                    <ul style={{listStyle: 'none'}}>
                        { camObj.map((option, index) => (
                            <li key={option.id} style={{marginBottom: '10px'}}>
                                <ButtonRound color="blue" text={(option.label.includes('front') || option.label.includes('Front') || option.label.includes('FRONT') ? 'เปิดใช้กล้องหน้า' : (option.label.includes('back') || option.label.includes('Back') || option.label.includes('BACK') ? 'เปิดใช้กล้องหลัง' : option.label))} onClick={()=>{chooseCamera(option.id)}} />
                            </li>
                        ))}
                    </ul>
                    {/* <Link to={`/main/?query=${query}`}><BtnEllipse name="ยกเลิก"  color="grey" /></Link> */}
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errMessage}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errMessage}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorScanIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errMessage}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errMessage}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModalScan}/>
                </div>
            </Modal>
        </div>
    )
}

export default SetPassword