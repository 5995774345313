import React, { useEffect, useState, useCallback, useRef } from 'react'
import moment from 'moment'
import Modal from 'react-modal'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom';
import IconProfile from '../assets/img/user.png'
import IconEmptyCoupon from '../assets/img/ic-emptycoupon.svg';
import IconCheck from '../assets/img/ic-check.svg';
import IconLoading from '../assets/img/ic-loading.svg'
import IconShop from '../assets/img/ic-shop.png'
import Button3D from '../components/Button3D';
import ButtonRound from '../components/ButtonRound';

// const mockupData = {
//     "total_records": 12,
//     "row_count": 12,
//     "data": [
//         {
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-19 02:37:19"
//         },
//         {
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-19 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-19 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-18 02:37:19"
//         },
//         {
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-17 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-17 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-16 02:37:19"
//         },
//         {
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-15 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-15 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-14 02:37:19"
//         },
//         {
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-14 02:37:19"
//         },{
//             "shop_name": "Keen-GT",
//             "qrcode": "uRlF_0su",
//             "total_ecp": 1,
//             "total_face_value": 16,
//             "scan_date": "2022-06-14 02:37:19"
//         }
//     ]
// }

function Home() {
    const history = useNavigate();
    const scrollRef = useRef(null)

    // const [isLoading, setIsLoading] = useState(true)
    // const [modalLogout, setModalLogout] = useState(false)
    // const [username, setUsername] = useState('Username')
    // const [selectedIndex, setSelectedIndex] = useState(0)
    // const [collapseMenu, setCollapseMenu] = useState(false)
    const [statusData, setStatusData] = useState({})
    const [couponsData, setCouponsData] = useState({});
    const [couponsListData, setCouponsListData] = useState({})
    const [selectDayData, setSelectDayData] = useState({ value: 1, label: 'วันนี้' })
    const [selectCountData, setSelectCountData] = useState(10)
    const [selectStartData] = useState(0)
    const [errModal, setErrModal] = useState(false)
    const [errMsg] = useState('ไม่สามารถทำรายการได้')
    const [isLoading, setIsLoading] = useState(false)
    const [isLazyLoading, setIsLazyLoading] = useState(false)
    const [windowH] = useState(window.innerHeight)
    const [lastLogin] = useState(localStorage.getItem('lastLogin'))

    let apiLocation = localStorage.getItem('authLocation');
    let apiXKey = localStorage.getItem('authXkey')
    const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ษ.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']

    const fetchCouponsData = useCallback(async (days,count,start) => {
        
        const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/peddleruser/list_peddler_qrcode?searchdays=${days}&order_by=scan_date&desc=1&start=${start}&count=${count}&search=`, {
                method: 'GET',
                // body: JSON.stringify(dataLogin),
                headers: {
                    "x-application-secret-key": apiXKey,
                    "Authorization": "Bearer "+localStorage.getItem('authToken'),
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            res
            .json()
            .then(res => {
                if (res.success === 0) {
                    setErrModal(true);
                } else {
                    // console.log('data coupons:',res)
                    setIsLoading(false)
                    setIsLazyLoading(false)
                    setCouponsData(res) // for staging
                    setCouponsListData(res.data)
                    // setCouponsData(mockupData) // for Test
                    // setCouponsListData(mockupData.data)
                    // console.log('**************************')
                    // console.log('!!!! Get Data Success !!!!')
                    // console.log('**************************')
                }
            })
            .catch(err => {
                console.log(err);
                history('/')
                setIsLoading(false)
            });
    },[apiXKey, history])

    useEffect(() => {
        
        setIsLoading(true)
        let unamounted = false
        

        const fetchStatus =  async() => {
            // console.log(apiXKey)
            // console.log(localStorage.getItem('authToken'))
            const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/peddleruser/status`, {
                method: 'GET',
                // body: JSON.stringify(dataLogin),
                headers: {
                    "x-application-secret-key": apiXKey,
                    "Authorization": "Bearer "+localStorage.getItem('authToken'),
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            res
            .json()
            .then(res => {
                if(!unamounted) {
                    console.log('data status:',res)
                    setIsLoading(false)
                    setStatusData(res)
                    unamounted = true
                    
                    fetchCouponsData(selectDayData.value, selectCountData, selectStartData)
                }
            })
            .catch(err => {
                console.log(err);
                history('/')
                setIsLoading(false)
            });
        }

        // if(!!localStorage.getItem('authLocation')) {
        //     fetchStatus()
        // } else {
        //     history('/')
        // }
        fetchStatus()

        return ()=> { 
            unamounted = true 
        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchLogout =  async() => {
        setIsLoading(true)
        // console.log(apiXKey)
        // console.log(authDataObj.token)
        await fetch(`https://${apiLocation}/ecp/api/peddleruser/logout`, {
            method: 'POST',
            // body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        history('/')

        console.log('xkey', apiXKey)
        console.log('apilocation',apiLocation)
        setIsLoading(false)
    }

    const options = [
        { value: 1, label: 'วันนี้' },
        { value: 7, label: 'ในรอบ 7 วัน' },
        { value: 30, label: 'ในรอบ 30 วัน' }
    ]

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'gray',
            backgroundImage:  state.isSelected ? `url(${IconCheck})` : null,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '15px',
            backgroundPosition: '96% center',
            backgroundColor: state.isSelected ? 'lightgray' : 'white',
            textIndent: 0,
            fontSize: '1.4rem',
            padding: '2px 12px',
          }),
    }

    const getCouponsData = (option) => {
        setIsLoading(true)
        // console.log(option)
        setSelectDayData(option)
        fetchCouponsData(option.value, 10, selectStartData)
        setSelectCountData(10)

        scrollRef.current.scrollTop = 0
    }

    const calcUpdatedDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        const dataTime = moment(val).format('HH:mm')
        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' '+dataTime)
    }

    const calcFilterDate = (val) => {
        const dataDate = moment(val).format('D')
        const dataMonth = Number(moment(val).format('MM'))
        const dataYear = (Number(moment(val).format('YYYY')) + 543) - 2500
        // const dataTime = moment().format('HH:mm')

        // const date7 = moment().add(7,'days')
        // const dataDate7 = moment(date7).format('D')
        // const dataMonth7 = Number(moment(date7).format('MM'))
        // const dataYear7 = (Number(moment(date7).format('YYYY')) + 543) - 2500

        // const date30 = moment().add(30,'days')
        // const dataDate30 = moment(date30).format('D')
        // const dataMonth30 = Number(moment(date30).format('MM'))
        // const dataYear30 = (Number(moment(date30).format('YYYY')) + 543) - 2500

        // if(val === 1) {
        //     return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear)
        // } else if(val === 7) {
        //     return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' - '+dataDate7+' '+monthArr[dataMonth7 -1]+' '+dataYear7 )
        // } else {
        //     return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear+' - '+dataDate30+' '+monthArr[dataMonth30 -1]+' '+dataYear30 )
        // }

        return (dataDate+' '+monthArr[dataMonth -1]+' '+dataYear)
    }

    const getCouponsGroup = () => {
        let result = []

        let groupByKeyObj = couponsListData.reduce((hash, obj) => {
            if(moment(obj['scan_date']).format('YYYY-MM-DD') === undefined) return hash; 
            return Object.assign(hash, { [moment(obj['scan_date']).format('YYYY-MM-DD')]:( hash[moment(obj['scan_date']).format('YYYY-MM-DD')] || [] ).concat(obj)})
        }, {})

        for(let i=0; i<Object.keys(groupByKeyObj).length; i++) {
            let kv = Object.keys(groupByKeyObj)[i]
            let vv = groupByKeyObj[kv]

            result.push(
                <div key={i} className="couponGroup">
                    <span className="couponGroupDate">{calcFilterDate(kv)}</span>
                    {
                        vv.map((item,i)=>{
                            return (
                                <div key={i} className="couponcard">
                                    
                                    <div className="shopname">
                                        <div className="col-l">
                                            <img src={IconShop} alt="shop"/>
                                            <h3>{!!item.shop_name ? item.shop_name : 'shop name'}</h3>
                                        </div>
                                        <div className="col-r">
                                            <p>มูลค่า: <span>{!!item.total_face_value ? item.total_face_value : 0} บาท</span></p>
                                        </div>
                                    </div>
                                    <div className="total">
                                        <p>จำนวน: <span>{!!item.total_ecp ? item.total_ecp : 0} ใบ</span></p>
                                        <p>ยอดซื้อขั้นต่ำ: <span>{!!item.total_minimum_purchase ? item.total_minimum_purchase : 0} แถว</span></p>
                                    </div>
                                    <div className="detail">
                                        <p>{calcUpdatedDate(item.scan_date)}</p>
                                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                        <p>รหัส: {item.qrcode}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
            
        }

        return result
    }

    const scrolling = () => {

        let scrollH = scrollRef.current.scrollHeight
        let scrollT = scrollRef.current.scrollTop
        let elementH = scrollRef.current.clientHeight
        let scrollD = Math.ceil(scrollT + elementH)
        // console.log('scroll height:',scrollH,' top:',scrollT,' el height:', elementH)
        // console.log('scroll top + el height = down:',scrollD)
        // console.log('---------------------------')

        if(couponsData.row_count >= couponsData.total_records) {
            setIsLazyLoading(false)
        } else {
            // Scrolling
            if(scrollD === scrollH) {
                // Scroll Finish
                // alert('Scroll Finish!!')
                setIsLazyLoading(true)
                // setSelectStartData(selectStartData + 10)
                fetchCouponsData(selectDayData.value, selectCountData + 10, selectStartData)
                setSelectCountData(selectCountData + 10)
            } else {
                setIsLazyLoading(false)
            }
        }
    }

    const closeModal = () => {
        setErrModal(false); 
        setIsLoading(false);
        setIsLazyLoading(false);
    }


    Modal.setAppElement('#root');

    return (
        <div className="home-page">
            {
                isLoading ? <div className="modal-overlay"><img src={IconLoading} alt="...Loading..." /></div> : null
            }
            <div className="header">
                <div className="profile">
                    <div className="col-l">
                        <div className="top">
                            <img src={IconProfile} alt="profile" />
                            <h3>{!!statusData.username? statusData.username : ''} {!!statusData.peddler_name? '- '+statusData.peddler_name : ''}</h3>
                        </div>
                        <div className="down">
                            <p>{!!statusData.shop_name? statusData.shop_name : 'Shopname'}</p>
                        </div>
                    </div>

                    <div className="col-r">
                        <button className="btn-logout" onClick={()=>{fetchLogout()}}><p>ออกจากระบบ</p></button>
                    </div>
                </div>
                <div className="status">
                    <div className="col-l">
                        <h3>ประวัติการสแกน</h3>
                    </div>

                    <div className="col-r">
                        <p>({couponsData.total_records} ครั้ง)</p>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="select-period">
                    <Select 
                        styles={customStyles} 
                        options={options} 
                        defaultValue={{value: 1, label: 'วันนี้'}} 
                        value={selectDayData} 
                        onChange={option=>{
                            getCouponsData(option)
                        }} />
                </div>

                {
                    !!couponsData.total_records ?
                        <div className="coupon-list-box" ref={scrollRef} onScroll={scrolling} style={isLazyLoading ? {height: windowH - 180+'px', paddingBottom: '60px', overflowY: 'hidden', overflowX: 'hidden'} : {height: windowH - 180+'px', paddingBottom: '60px', overflowY: 'auto', overflowX: 'hidden'}}>
                            <div className="couponDate">
                                {
                                    getCouponsGroup()
                                }
                            </div>
                            <div className="lazy-loading">
                                {
                                    isLazyLoading ? <img src={IconLoading} alt="...Loading..." /> : null
                                }
                            </div>
                        </div>
                    :
                        <div className="empty-coupon txt-center">
                            <img src={IconEmptyCoupon} alt="icon" />
                            <h3 className="font-black">คุณยังไม่มีคูปองส่วนลด</h3>
                            <p>คูปองส่วนลดของคุณท่ี่สามารถแลกส่วนลดได้<br/>จะถูกเก็บไว้ที่นี่</p>
                        </div>
                }
            </div>

            <div className="btn-row _bottom _center" style={{bottom: '0', transform: 'translateY(0)'}}>
                <Button3D text="สแกนคูปอง" onClick={()=>{ history('/scan')}} />

                {
                    lastLogin !== 'null' ?
                        <p>เข้าระบบครั้งล่าสุด { calcUpdatedDate(lastLogin) } น.</p>
                    :
                    null
                }
            </div>

            <Modal 
                isOpen={errModal} 
                className="_modal box-style modal-date" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_date-modal center" style={{margin: '20px 10px 10px'}}>

                    <h3 style={{lineHeight: '1'}}>{errMsg}</h3>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={()=>{ closeModal() }}/>
                        
                </div>
            </Modal>
        </div>
    )
}

export default Home