import React, {useState} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthContext from '../components/AuthContext';
import Home from './Home';
import Login from './Login';
import Scan from './Scan';
import SetPassword from './SetPassword';

function Main() {
  const [authData, setAuthData] = useState(null)

  return (
    <div style={{maxWidth: '768px', margin: '0 auto'}}>
      <AuthContext.Provider value={{authData, setAuthData}}>
          <BrowserRouter>
              <Routes>
                  <Route exact path="/" element={ <Login /> } />
                  <Route path="/setpassword" element={ <SetPassword /> } />
                  <Route path="/home" element={ <Home /> } />
                  <Route path="/scan" element={ <Scan /> } />
              </Routes>
          </BrowserRouter>
      </AuthContext.Provider>
    </div>
  )
}

export default Main